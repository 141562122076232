<template>
  <div class="container  p-3" id="destinations">
    <h1 class="h1-mobile">A destination for everyone</h1>
    <div class="row mx-auto my-auto justify-content-center">
      <div id="recipeCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner text-center" role="listbox">
          <div class="carousel-item active">
            <div class="col-md-4">
              <div class="card-carousel">
                <div class="card-img">
                  <img
                    src="~@/assets/images/layout/carousel/vallarta.png"
                    class="img-fluid"
                  />
                </div>
                                <div class="card-body">
                  <h5 class="card-title">Puerto Vallarta</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="col-md-4">
              <div class="card-carousel">
                <div class="card-img">
                  <img
                    src="~@/assets/images/layout/carousel/cabo.png"
                    class="img-fluid"
                  />
                </div>
                                <div class="card-body">
                  <h5 class="card-title">Los Cabos</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="col-md-4">
              <div class="card-carousel">
                <div class="card-img">
                  <img
                    src="~@/assets/images/layout/carousel/cancun.png"
                    class="img-fluid"
                  />
                </div>
                                                <div class="card-body">
                  <h5 class="card-title">Cancun</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="col-md-4">
              <div class="card-carousel">
                <div class="card-img">
                  <img
                    src="~@/assets/images/layout/carousel/riviera-maya.png"
                    class="img-fluid"
                  />
                </div>
                                                <div class="card-body">
                  <h5 class="card-title">Riviera Maya</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="col-md-4">
              <div class="card-carousel">
                <div class="card-img">
                  <img
                    src="~@/assets/images/layout/carousel/loreto.png"
                    class="img-fluid"
                  />
                </div>
                <div class="card-body">
                  <h5 class="card-title">Loreto</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev bg-transparent w-aut"
          href="#recipeCarousel"
          role="button"
          data-bs-slide="prev"
        >
         <span class="material-icons-outlined" aria-hidden="true">arrow_back_ios</span>
        </a>
        <a
          class="carousel-control-next  w-aut"
          href="#recipeCarousel"
          role="button"
          data-bs-slide="next"
        >
          <span class="material-icons-outlined" aria-hidden="true">arrow_forward_ios</span>

        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
  methods: {
    bannerSlide() {
      let items = document.querySelectorAll(".carousel .carousel-item");

      items.forEach((el) => {
        const minPerSlide = 4;
        let next = el.nextElementSibling;
        for (var i = 1; i < minPerSlide; i++) {
          if (!next) {
            // wrap carousel by using first child
            next = items[0];
          }
          let cloneChild = next.cloneNode(true);
          el.appendChild(cloneChild.children[0]);
          next = next.nextElementSibling;
        }
      });
    },
  },
  mounted() {
    this.bannerSlide();
  },
};
</script>

<style>
.carousel-control-next, .carousel-control-prev {
  text-decoration: none;
}
@media (max-width: 767px) {
  .carousel-inner .carousel-item > div {
    display: none;
  }
  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }
  .h1-mobile {
  font-size: 40pt;
}

}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {
  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(33%);
  }

  .carousel-inner .carousel-item-start.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-33%);
  }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}

.carousel-item {
  background-color: rgb(226, 239, 232);
}

.card {
  background-color: rgb(226, 239, 232);
  border: none;
}

.carousel-inner {
  background-color: rgb(226, 239, 232);
}

.card-title{
  color:   rgb(12, 148, 145);
}

.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: left;
    width: 15%;
    padding: 0;

    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
      color: rgb(161, 161, 165);
}

.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 15%;
    padding: 0;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
  justify-content: right;
  color: rgb(161, 161, 165);

}

.card-carousel {
padding: 25px;
}

</style>