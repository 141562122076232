<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light nav-bar">
      <div class="container ">
        <div class="col-xs-6 p-3">
          <router-link :to="(navIconSelected())"><img src="~@/assets/images/layout/logo-AM.svg" alt="" /></router-link>
        </div>
        <div class="p-4">
          <div
           id="hamburger-1"
            class="hamburger navbar-toggler material-icons "
            :class="{isActive: toggler}"
            data-bs-toggle="collapse"
            data-bs-target="#navbarToggler"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            @click="selectedToggler()"
          >
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
          </div>
        </div>
                <!-- <div class="col-xs-6 p-3">
          <button
            class="navbar-toggler material-icons"
            data-bs-toggle="collapse"
            data-bs-target="#navbarToggler"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            menu
          </button>
        </div> -->
        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="navbar-nav ms-auto flex-nowrap">
            <li
              @click="selectedOption(option)"
              v-for="option in navBarOptions"
              :key="option.hash"
              class="nav-item"
            >
              <router-link :to="selectedSection(option.hash)">
              <span class="nav-link" aria-current="page">{{
                option.label
              }}</span></router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { Collapse } from "bootstrap";
export default {
  data() {
    return {
      collapse: {},
      navBarOptions: [
        {
          hash: "#referralform",
          label: "Add referrals",
        },
        {
          hash: "#whn",
          label: "What next?",
        },
        {
          hash: "#destinations",
          label: "Destinations",
        },
        {
          hash: "#faqs",
          label: "FAQs",
        },
      ],
      referrer: '',
      toggler: false
    };
  },
  methods: {
    initNavBar() {
      const navBarCollapse = this.$el.querySelector("#navbarToggler");
      const collapse = new Collapse(navBarCollapse, {
        toggle: false,
      });

      return collapse;
      
    },
    selectedOption(option) {
      window.location.hash = option.hash;
      // this.collapse.hide();
     this.selectedToggler();
    },
    selectedSection() {
      this.referrer = this.$route.query.ref;
      console.log(this.referrer);
      return {name: 'home',
      query: {ref: this.referrer}}
    },
    navIconSelected() {
            return {name: 'home',
      query: {ref: this.referrer}}
    },
      selectedToggler() {
this.toggler = !this.toggler;
  },
  },
  mounted() {
    this.collapse = this.initNavBar();
    // console.log(this.$el);
  },
  computed: {
  }
};
</script>

<style scoped>
.navbar-toggler {
    border-color: transparent;
}
.nav-item {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.nav-link {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: "Light";
  text-decoration: none;
}

small {
  color: #1874a2;
}

.navbar-light .navbar-nav .nav-link {
  color: #1874a2;
}

@media (max-width: 991px) {
.navbar-light .navbar-nav .nav-link {
  color: #f0f0f0;
}
.carousel-control-next {
  text-decoration: none;
}
  .nav-bar{
        position: fixed;
        right: 0;
        left: 0;   
        border-radius: 0;
        top: 0;
        border-width: 0 0 1px;
    z-index:2;
    font-size: 16pt;
    background-color: #E5EEE9;
  }
  .navbar-collapse {
    background-color: #006581!important;
    text-align: center;
    padding: 10px;
    margin-top: 15px;
    width: 100%;
    min-height: 100vh;
    z-index:1;

  }

  nav .navbar-nav li a {
    color: white !important;
  }

  .container {
    padding: 0px;
  }
  .navbar {
    padding: 0px;
  }
  .container-fluid {
    padding: 0px;
  }
  .nav-item {
    margin-top: 3rem;
  }
  .fixed {
    position: fixed;
    background-color: #e5eee9;
    z-index:1;
  }
}

.three{
  padding: 0px 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #ecf0f1;
  text-align: center;
}

.hamburger .line{
  width: 45pt;
  height: 5px;
  background-color: #006581;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
}

/* ONE */

#hamburger-1.isActive .line:nth-child(2){
  opacity: 0;
}

#hamburger-1.isActive .line:nth-child(1){
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
}

#hamburger-1.isActive .line:nth-child(3){
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
}

</style>