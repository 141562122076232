<template>
<!--Contact Form-->
<div class="container mb-3 p-3" id="contactConcierge">
  <div class="row  align-items-center">
    <div class="col-sm-4">
      <img src="~@/assets/images/layout/concierge.png" alt="" class="img-fluid ">
    </div>
    <div class="col-sm-8">
      <div class="row">
        <h1 class="h1-mobile">Contact your referral concierge</h1>
      </div>
          </div>
      </div>
    </div>
<!--Ends Contact Form-->
</template>

<script>

</script>

<style>
.info-contact{
  color: #707070;
}

.btn-send {
  width: 104px;
  height: 47px;
}
.cf-icons{
  color: #006581;
}
@media (max-width: 991px) {
  .h1-mobile {
  font-size: 40pt;
}
}
</style>