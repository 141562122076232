<template>
<div class="banner">
    <!-- <img src="/assets/images/bg.jpg" class="card-img" alt="..."> -->
    <div class="wrap w-100 mb-5">
      <div class="image-cover h-100 w-100 d-flex flex-column">
        <div class="cardB container d-none d-md-block">
          <div class="col-lg-5">
            <div class="h2-cover display-6 fw-bold">Earn Your Way to</div><br>
            <h1 class="txt-carousel">Amazing</h1><br>
            <p class="pBanner">Everyone wins, <span style="color: #006581"><b>refer your friends and Family,</b></span> they get special discount package and you get exclusive
              benefits to make your vacation even more incredible.</p>
            <button type="" class=" btn-cover" ><a class="a-btn" href="#referralform">Start Referring</a></button>
          </div>
        </div>
          <div class="container c-mobile d-flex d-md-none flex-column h-100 p-4 pt-5" >
          <div class="col-lg-6 p-3 ps-3">
            <h2 class=" h2-cover display-1 fw-bold">Earn Your Way to</h2>
            <h1 class="txt-carousel-mobile mt-4">Amazing</h1>
            <p class="pBanner">Everyone wins, <span style="color: #006581">refer your friends and Family,</span> they get special discount package and you get exclusive
              benefits to make your vacation even more incredible.</p>
          </div> 
         <div class="col-btn col-md-12 text-center">
            <button type="button" class="btn-cover mt-auto"><a class="a-btn" href="#referralform">Start Referring</a></button>
         </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>

</script>

<style scoped>

@media only screen and (max-width: 600px) {
  .image-cover {
background-image: url(~@/assets/images/layout/cover-sm.jpg); 
right: -2rem; top: -2rem;
transition:  0.5s;
}
}
@media only screen and (min-width: 600px) {
  .image-cover {
background-image: url(~@/assets/images/layout/cover-sm.jpg); 
right: -2rem; top: -2rem;
transition:  0.5s;
}
}
@media only screen and (min-width: 768px) {
.image-cover {
background-image: url(~@/assets/images/layout/cover-md.jpg); 
right: -2rem; top: -2rem;
}
}
@media (min-width: 992px) {
.image-cover {
background-image: url(~@/assets/images/layout/cover-xl-lg.jpg); 
right: -2rem; top: -2rem;
transition:  0.5s;
}
}
@media only screen and (min-width: 1200px) {
.image-cover {
background-image: url(~@/assets/images/layout/cover-xxl.jpg); 
right: -2rem; top: -2rem;
transition:  0.5s;
}
}
.a-btn{
  color: white;
  text-decoration: none;
}

.a-btn :focus {
color: white;
}

.banner {
  font-family:'Montserrat', sans-serif;
    color: white;
}

.image-cover {
background-size: cover;
background-position: center;
right: -1rem; top: -1rem;
}

.cardB{
  margin-top: 105px;
}
.bg-wrapper {
  height: calc(100vh - 8.125rem);
}

.col-btn{
margin-top: 20%;
}

h1 {
  color: #006581;
  font-family: myFirstFont;
  font-size: 100px;
  font-weight: none;
}

.txt-carousel {
  color: #006581;
  font-family: myFirstFont;
  font-size: 120px;
  font-weight: normal;
}

.btn-cover {
  background: transparent linear-gradient(287deg, #03738A 0%, #B5D73D 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  border: none;
  color: white;
  border-radius: 5px;
  border: none;
  width: 200px;
  font-family:'Montserrat', 'Regular 400';
  padding: 20px;
  font-size: 20px;
}

.btn-cover:hover,
.btn-cover:active,
.btn-cover:focus,
.btn-cover.active {
  background: #B5D73D;
  color: #ffffff;
  border-color: #31C7D3;
}

.h2-cover {
  font-size: 36px;
}

h1 {
  color: #006581;
}

@media (max-width: 991px) {
  .banner {
  padding-top: 70px;
}
}

h1 {
  color: #006581;
  font-family: myFirstFont;
  font-size: 100px;
  font-weight: normal;
}

.txt-carousel {
  color: #006581;
  font-family: myFirstFont;
  font-size: 120px;
  font-weight: normal;
}
.pBanner{
  font-family:'Montserrat', 'Medium 500';
  font-weight: 500;
  font-size: 20px;
  text-align: justify;
  text-justify: inter-word;
}

.wrap {
  height: 900px;
}

.txtcarousel {
  color: #006581;
  font-family: myFirstFont;
  font-size: 120px;
}

@font-face {
  font-family: "myFirstFont";
  src: url("~@/assets/fonts/Oh.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
</style>