<template>
  <div class="container mt-3 mb-5 h-100" id="referralform">
    <form
      class="formRefCont p-4"
      method="post"
      @submit="
        addReferrals($event);
        saveReferral($event);
      "
    >
      <div class="row mb-4">
        <h1 class="h1wh">Enter your referrals</h1>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm formRef p-4 mb-3 me-3">
            <div class="row">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  >First name*</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="enteredReferrals.firstName"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  >Last name*</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="enteredReferrals.lastName"
                  required
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm">
                <label for="exampleFormControlInput1" class="form-label"
                  >Phone number*</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  v-model="enteredReferrals.phone"
                  placeholder="0000000000"
                  minlength="10"
                  maxlength="10"
                  required
                />
              </div>
              <div class="col-sm">
                <label for="exampleFormControlInput1" class="form-label address-input"
                  >Email address</label
                >
                <input
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder=""
                  v-model="enteredReferrals.email"
                  required
                />
              </div>
            </div>
            <div class="row mb-3" required>
              <label for="exampleFormControlInput1" class="form-label"
                >Relationship*</label
              >
              <div
                class="btn-group"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <div class="col">
                  <input
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    id="btnradio1"
                    autocomplete="off"
                    checked
                    @click="activate('Relative')"
                  />
                  <label class="btn btn-outline-secondary" for="btnradio1"
                    >Relative</label
                  >
                </div>
                <div class="col">
                  <input
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    id="btnradio2"
                    autocomplete="off"
                    @click="activate('Friend')"
                  />
                  <label class="btn btn-outline-secondary" for="btnradio2"
                    >Friend</label
                  >
                </div>
                <div class="col">
                  <input
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    id="btnradio3"
                    autocomplete="off"
                    @click="activate('Other')"
                  />
                  <label class="btn btn-outline-secondary" for="btnradio3"
                    >Other</label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label message"
                  >Message</label
                ><br />
                <textarea
                  class="message form-control"
                  name="w3review"
                  rows="3"
                  cols="50"
                  v-model="enteredReferrals.notes"
                ></textarea>
              </div>
            </div>
            <div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  v-if="showSubmitButton()"
                  class="btn btn-sub me-md-2 disabled"
                  type="submit"
                >
                  Submit
                </button>
                <button
                  v-else:
                  class="btn btn-sub me-md-2"
                  type="submit"
                  @click="
                    addReferrals;
                    saveReferral;
                  "
                >
                  Submit
                </button>
                <button
                  v-if="enteredReferrals.firstName.length === 0"
                  class="btn btn-add disabled"
                  type="submit"
                >
                  Add more +
                </button>
                <button
                  v-else:
                  class="btn btn-add"
                  type="button"
                  @click="addReferrals(true)"
                >
                  Add more +
                </button>
              </div>
            </div>
          </div>
          <div class="referralsTableBack col-sm mb-3 p-0 overflow-auto">
            <div v-if="referrals.length === 0">
              <div
                class="referralsTableUnable col-sm mb-3 p-0 overflow-auto"
              ></div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button class="btn btn-sub me-md-2 mb-4 disabled" type="button">
                  Submit all
                </button>
              </div>
            </div>
            <div v-else>
              <div
                v-for="(referral, index) in referrals"
                v-bind:key="referral"
                class="referralsTable p-4 mb-3"
              >
                <div class="row ps-2" style="background-color: ">
                  <div class="col refAtributes p-0">Name</div>
                  <div
                    class="col d-flex justify-content-end p-0"
                    style="background-color: "
                  >
                    <button
                      class="material-icons btn-delete"
                      type="button"
                      :key="referral.phone"
                      @click="deleteAlertMessage(index)"
                    >
                      delete_outline
                    </button>
                    <button
                      class="material-icons btn-delete"
                      type="button"
                      @click="editReferral(referral, index)"
                      data-bs-toggle="modal"
                      data-bs-target="#editReferral"
                    >
                      mode_edit
                    </button>
                  </div>
                </div>
                <div class="row name ps-2">
                  {{ referral.firstName }}
                </div>
                <hr />
                <div class="row ps-2">
                  <div class="col">
                    <div class="">
                      <div class="row refAtributes">Phone</div>
                      <div class="row">
                        {{ referral.phone }}
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row refAtributes">Email</div>
                    <div class="row">
                      {{ referral.email }}
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="row refAtributes">Relationship</div>
                    <div class="row">
                      {{ referral.relationship }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  class="btn btn-sub me-md-2 mb-4"
                  type="button"
                  @click="saveReferral(true)"
                >
                  Submit all
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tab
    id="editReferral"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel"></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForm()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="col-sm formRef mb-3 me-3">
              <div class="row">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label"
                    >First name*</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="enteredReferrals.firstName"
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label"
                    >Last name*</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="enteredReferrals.lastName"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm">
                  <label for="exampleFormControlInput1" class="form-label"
                    >Phone number*</label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    v-model="enteredReferrals.phone"
                  />
                </div>
                <div class="col-sm">
                  <label for="exampleFormControlInput1" class="form-label"
                    >Email address</label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    v-model="enteredReferrals.email"
                  />
                </div>
              </div>
              <div class="row mb-3">
                <label for="exampleFormControlInput1" class="form-label"
                  >Relationship*</label
                >

                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <div class="col">
                    <input
                      type="radio"
                      class="btn-check"
                      name="btnradio"
                      id="btnradio01"
                      autocomplete="off"
                      checked
                      @click="activate('Relative')"
                    />
                    <label class="btn btn-outline-secondary" for="btnradio01"
                      >Relative</label
                    >
                  </div>
                  <div class="col">
                    <input
                      type="radio"
                      class="btn-check"
                      name="btnradio"
                      id="btnradio02"
                      autocomplete="off"
                      @click="activate('Friend')"
                    />
                    <label class="btn btn-outline-secondary" for="btnradio02"
                      >Friend</label
                    >
                  </div>
                  <div class="col">
                    <input
                      type="radio"
                      class="btn-check"
                      name="btnradio"
                      id="btnradio03"
                      autocomplete="off"
                      @click="activate('Other')"
                    />
                    <label class="btn btn-outline-secondary" for="btnradio03"
                      >Other</label
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3">
                  <label
                    for="exampleFormControlInput1"
                    class="form-label message"
                    >Message</label
                  ><br />
                  <textarea
                    class="message"
                    name="w3review"
                    rows="3"
                    cols="50"
                    v-model="enteredReferrals.notes"
                  ></textarea>
                </div>
              </div>
              <div>
                <div class="d-grid gap-2 d-flex justify-content-md-end">
                  <button
                    type="button"
                    aria-label="Close"
                    @click="
                      removeReferrals(enteredReferrals.index);
                      addReferrals();
                    "
                    class="btn btn-sub"
                    data-bs-toggle="modal"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div v-show="saved">
    <div
      class="modal fade message-alert"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center">
            <i class="material-icons-outlined check pb-3">
              check_circle_outline
            </i>
            <h1 class="pb-3">Thanks, all ready set.</h1>
            <p>
              You've Submit your referrals successfully. <br />A Vacation
              concierge will be in touch whit <br />your friends son.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      enteredReferrals: {
        webhookID: "f363345a-a24f-df1a-92dc-9c697fb05eea",
        index: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        relationship: "",
        notes: "",
        referrer: "",
      },
      referrals: [],
      activeOption: null,
      selectedReferral: "",
      parameters: "",
      saved: false,
      waiting: false,
      navBarOption: {},
    };
  },
  methods: {
    addReferrals() {
      let form = document.querySelector("form");
      if (form.checkValidity()) {
        this.enteredReferrals.referrer = this.parameters;
        this.referrals.push(this.enteredReferrals);
        this.enteredReferrals = this.referral();
      } else {
        form.reportValidity();
        console.log(form.checkValidity());
        console.log(this.enteredReferrals);
      }
    },
    referral() {
      return {
        webhookID: "f363345a-a24f-df1a-92dc-9c697fb05eea",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        relationship: "",
        notes: "",
        referrer: this.parameters,
      };
    },
    removeReferrals(idx) {
      this.referrals.splice(idx, 1);
      console.log(idx);
    },
    activate(option) {
      this.activeOption = option;
      this.enteredReferrals.relationship = this.activeOption;
    },
    editReferral(referral, index) {
      (this.enteredReferrals.firstName = referral.firstName),
        (this.enteredReferrals.lastName = referral.lastName),
        (this.enteredReferrals.phone = referral.phone),
        (this.enteredReferrals.email = referral.email),
        (this.enteredReferrals.relationship = referral.relationship),
        (this.enteredReferrals.notes = referral.notes);
      this.enteredReferrals.index = index;
    },
    cleanForm() {
      (this.enteredReferrals.firstName = ""),
        (this.enteredReferrals.lastName = ""),
        (this.enteredReferrals.phone = ""),
        (this.enteredReferrals.email = ""),
        (this.enteredReferrals.relationship = ""),
        (this.enteredReferrals.notes = ""),
        (this.enteredReferrals.index = "");
    },
    saveReferral(e) {
      let formValidation = "";
      if (e == true) {
        formValidation = true;
      } else {
        let form = e.target;
        e.preventDefault();
        formValidation = form.checkValidity();
      }

      console.log("hello");
      debugger; // eslint-disable-line no-debugger
      // "https://localhost:44308/api/webhooks/incoming/genericjson/newreferral?code=52f2aba8f603473eafb5df370e02d169";
      if (formValidation) {
        this.loadingMessage();
        var url =
          "https://developers.eplat.com/api/webhooks/incoming/genericjson/newreferral?code=52f2aba8f603473eafb5df370e02d169";
        this.referrals.forEach(async (referral) => {
          var data = referral;
          console.log("referral");
          const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then(() => {
              // this.resetValues();
              this.saved = true;
            })
            .catch((error) => {
              console.log(error);
            });
          console.log("here", response);
          this.alertMessage().alert("close");
        });
      }
      this.referrals.length = 0;
      e.preventDefault();
      this.cleanForm();
    },
    resetValues() {
      this.form = this.initialValues();
      document.getElementById(this.id).classList.remove("was-validated");
    },
    initialValues() {
      return {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        relationship: null,
        notes: null,
        referrer: null,
      };
    },
    randomString(length) {
      const chars =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz".split(
          ""
        );

      if (!length) {
        length = Math.floor(Math.random() * chars.length);
      }

      var str = "";
      for (var i = 0; i < length; i++) {
        str += chars[Math.floor(Math.random() * chars.length)];
      }
      return str;
    },
    showSubmitButton() {
      var showB;
      if (
        this.enteredReferrals.firstName.length == 0 ||
        this.referrals.length > 0
      ) {
        showB = true;
      } else {
        showB = false;
      }
      return showB;
    },
    alertMessage() {
      return this.$swal.fire({
        icon: "success",
        title: "Thanks, all ready set.",
        text: "You´ve submited your referrals successfully. A Vacation concierge will be in touch with your friends soon.",
        color: "#0C9491",
        showConfirmButton: false,
        timer: 5000,
        iconColor: "#0C9491",
        backdrop: `rgba(0,101,129,0.7)`,
      });
    },
    loadingMessage() {
      return this.$swal.fire({
        text: "Submitting",
        color: "#0C9491",
        showConfirmButton: false,
        iconColor: "#0C9491",
        backdrop: `rgba(0,101,129,0.7)`,
        width: 160,
        timerProgressBar: true,
        // timer: 3000,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
    },
    errorMessage() {
      return this.$swal.fire({
        icon: "success",
        title: "Thanks, all ready set.",
        text: "You´ve submited your referrals successfully. A Vacation concierge will be in touch with your friends soon.",
        color: "#0C9491",
        showConfirmButton: false,
        timer: 5000,
        iconColor: "#0C9491",
        backdrop: `rgba(0,101,129,0.7)`,
      });
    },
    deleteAlertMessage(index) {
      return this.$swal
        .fire({
          title: "Are you sure to delete this referral?",
          text: "You won't be able to revert this!",
          color: "#0C9491",
          backdrop: `rgba(0,101,129,0.7)`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#006581",
          cancelButtonColor: "#02c0d1",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.removeReferrals(index);
            this.$swal.fire({
              icon: "success",
              title: "Referral deleted",
              color: "#0C9491",
              showConfirmButton: false,
              timer: 3000,
              iconColor: "#0C9491",
              backdrop: `rgba(0,101,129,0.7)`,
            });
          }
        });
    },
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    console.log(params.get("ref"));
    this.parameters = params.get("ref");
    // this.alertMessage().alert('close');
    // this.loadingMessage()
    // this.deleteAlertMessage();
  },
};
</script>

<style>
.message {
  width: 100%;
  resize: none;
}

.btn-delete {
  background: none;
  border: none;
  color: #4e4e4f;
}

.formRefCont {
  background: transparent linear-gradient(118deg, #03738a 0%, #b5d73d 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.formRef {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

.referralsTableUnable {
  background-color: white;
  opacity: 0.3;
  border-radius: 5px;
  height: 180px;
}

.referralsTableBack {
  /* background-color: green;
  opacity: 0.3;
  border-radius: 5px; */
  overflow-y: scroll;
  max-height: 561px;
  border-radius: 5px;
}

.referralsTable {
  background-color: white;
  border-radius: 5px;
}

.btn-outline {
  border-color: #31c7d3;
  border-width: 3px;
  text-align: left;
}

.btn-outline:hover,
.btn-outline:active,
.btn-outline:focus,
.btn-outline.active {
  background: #31c7d3;
  color: #ffffff;
  border-color: #31c7d3;
}

.btn-sub {
  background-color: #006581;
  color: white;
  border-radius: 5px;
  border: none;
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn-add {
  background-color: white;
  color: #006581;
  border-radius: 5px;
  border-color: #0c9491;
  font-family: "Montserrat", sans-serif;
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn-sub:hover,
.btn-sub:active,
.btn-sub:focus,
.btn-sub.active {
  background: #02c0d1;
  color: #ffffff;
}

.btn-add:hover,
.btn-add:active,
.btn-add:focus,
.btn-add.active {
  background: #006581;
  color: #ffffff;
  border-color: #006581;
}

.btn-outline-secondary {
  width: 100%;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary:target {
  background: #0c9491;
  color: #ffffff;
  border-color: #006581;
}

.h1wh {
  color: #ffffff;
}

.name {
  color: #0c9491;
  font-weight: bold;
}

.refAtributes {
  font-size: 12px;
}

.modal-backdrop {
  background-color: #0c9491;
  opacity: 0.7;
}

@media (max-width: 991px) {
  .h1wh {
    color: #ffffff;
    font-size: 40pt;
  }
}

@media (max-width: 600px) {
  .address-input {
    padding-top: 10pt;
  }
}

.message-alert {
  font-family: "Poppins", sans-serif;
  color: #262626;
}

.message-alert h1 {
  color: #0c9491;
}

.check {
  font-size: 150px;
  color: #0c9491;
}

.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary:active {
  background-color: #0c9491;
}

.swal2-styled.swal2-cancel {
  border: -41;
}
</style>