<template>
  <div class="container mb-1 p-3" id="terms">


    <section
      class=" text-center bg-image"

    >
      <div class="row py-lg-5 cover">
        <div class="col-lg-8 col-md-8 mx-auto">
          <h1 class="pb-0 pt-5 oh-golds">Terms and Conditions</h1>
          <p class="lead p-3">
            The Villa Group & Tafer Resorts are making this vacation package
            offers available to you. Besides meeting the qualifications and
            attending the Vacation Ownership presentation, there are no other
            obligations. Come enjoy your stay with us and learn about the
            benefits of Vacation Ownership! <br />
            Primary Travelers must meet all of the Resort's Qualifications
            below. <br />
            Resort Fees (if applicable) are collected during your verification
            call. This fee is a reservation processing fee.<br />
          </p>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="row py-3">
        <div
          class="col-12 col-md-4 col-lg-3 order-2 pt-4 d-sm-none d-md-block"
          id="sticky-sidebar"
        >
          <div class="sticky-top">
            <div class="nav flex-column bg-light p-3">
              <h4>Navigation</h4>
              <hr />
              <a href="#main-1" class="nav-link">Credit Card</a>
              <a href="#main-2" class="nav-link">Presentation</a>
              <a href="#main-3" class="nav-link">Age Qualification</a>
              <a href="#main-4" class="nav-link">Income</a>
              <a href="#main-5" class="nav-link">Language</a>
              <a href="#main-6" class="nav-link">Identification</a>
              <a href="#main-7" class="nav-link">Marital Status</a>
              <a href="#main-8" class="nav-link">Countries</a>
              <a href="#main-9" class="nav-link">Group Travel</a>
              <a href="#main-10" class="nav-link">Additional Terms</a>
            </div>
          </div>
        </div>
        <div class="col-12 d-sm-block d-md-none">
          <div class="bg-light p-3">
            <h4>Navigation</h4>
            <hr />
            <div class="nav">
              <a href="#main-1" class="nav-link-2">Credit Card</a>
              <a href="#main-2" class="nav-link-2">Presentation</a>
              <a href="#main-3" class="nav-link-2">Age Qualification</a>
              <a href="#main-4" class="nav-link-2">Income</a>
              <a href="#main-5" class="nav-link-2">Language</a>
              <a href="#main-6" class="nav-link-2">Identification</a>
              <a href="#main-7" class="nav-link-2">Marital Status</a>
              <a href="#main-8" class="nav-link-2">Countries</a>
              <a href="#main-9" class="nav-link-2">Group Travel</a>
              <a href="#main-10" class="nav-link-2">Additional Terms</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-9" id="main">
          <div class="pt-4" id="main-1">
            <h2>Credit Card</h2>
            <ul>
              <li>
                The qualifying single person, OR one of the spouses in a legally
                married couple must bring with them and present to the hostess
                during check in for the presentation
              </li>
              <li>
                At least one Major Credit Card in the name of the qualified
                person(s). We DO NOT accept Debit Cards, Check Cards or Business
                Account Cards. We do not accept credit cards in the name of a
                non-qualifying person or in the name of a corporation.
              </li>
              <li>
                WE ONLY ACCEPT VISA, MASTERCARD, DISCOVER or AMERICAN EXPRESS
                that are NOT associated with your bank accounts.
              </li>
              <li>Expired credit cards DO NOT QUALIFY.</li>
              <li>
                If these or any other qualifications are not met, your
                promotional reservation may be cancelled without notice.
              </li>
            </ul>
          </div>
          <div class="pt-4" id="main-2">
            <h2>Presentation</h2>
            <ul>
              <li>
                Attendance to a 90 minute vacation ownership presentation at The
                Villa Group Resort the day after arrival to Mexico is required.
              </li>
              <li>
                You may not attend another vacation ownership presentation
                during your visit to the destination.
              </li>
              <li>
                You are not eligible if you have previously toured any of The
                Villa Group resorts within 6 months of your arrival, or if you
                have already used a marketing promotion with Villa Group Resorts
                twice.
              </li>
            </ul>
          </div>
          <div class="pt-4" id="main-3">
            <h2>Age Qualification</h2>
            <h5>Cabo &amp; Vallarta</h5>
            <ul>
              <li>
                Married couples - at least one person must be between the ages
                of 30 and 70
              </li>
              <li>Legally single women - between 30 to 70 years of age</li>
              <li>Legally single men - between 35 and 59 years of age</li>
              <li>YOU MAY BE ASKED TO PROVIDE COPIES OF ID AS PROOF.</li>
            </ul>
            <h5>Cancun</h5>
            <ul>
              <li>
                Married couples - at least one person must be between the ages
                of 30 and 70.
              </li>
              <li>
                Legally single women or men- between 30 to 70 years of age
              </li>
              <li>YOU MAY BE ASKED TO PROVIDE COPIES OF ID AS PROOF.</li>
            </ul>
            <h5>Loreto</h5>
            <ul>
              <li>
                Legally Married couples must be between 30-69 years old for
                American couples, or 30-64 years old for Canadian couples
                (either spouse may meet the age requirement).
              </li>
              <li>
                Legally Single American women 30 to 69 years and legally single
                Canadian women 30 to 64 years.
              </li>
              <li>
                Legally single men - between 30 and 70 years of age, citizens of
                United States or Canada.
              </li>
              <li>YOU MAY BE ASKED TO PROVIDE COPIES OF ID AS PROOF.</li>
            </ul>
          </div>
          <div class="pt-4" id="main-4">
            <h2>Income</h2>
            <ul>
              <li>
                Minimum $50,000 Annual Income (singles must meet this
                qualification on their own, or combined income for legally
                married couples).
              </li>
              <li>
                Must be employed full time or fully retired - part time
                employment does not qualify.
              </li>
              <li>Must not be a full time student.</li>
            </ul>
          </div>
          <div class="pt-4" id="main-5">
            <h2>Language</h2>
            <p>
              Both qualified participants must be fluent in either English or
              Spanish.
            </p>
          </div>
          <div class="pt-4" id="main-6">
            <h2>Identification</h2>
            <ul>
              <li>
                The qualified person(s) must present a major credit card and
                government issued photo ID at the tour reception desk.
              </li>
              <li>
                Married couples may be asked to show proof of such (Government
                issued picture ID with matching addresses, and/or marriage
                certificate. Both spouses must bring ID.
              </li>
            </ul>
          </div>
          <div class="pt-4" id="main-7">
            <h2>Marital Status</h2>
            <ul>
              <li>
                Offer is valid for legally married couples and legally single
                women or men.
              </li>
              <li>
                All guests must attend the presentation, including all children
                in the reservation.
              </li>
            </ul>
          </div>
          <div class="pt-4" id="main-8">
            <h2>Countries</h2>
            <p>
              Offer is valid only for residents of the USA or Canada who meet
              all other qualifications. Valid USA or Canadian government issued
              ID must be presented by all guests. Non-citizens must have been
              residents for a minimum of 5 years and present USA or Canadian
              government issued ID.
            </p>
          </div>
          <div class="pt-4" id="main-9">
            <h2>Group Travel</h2>
            <ul>
              <li>
                Maximum of 8 people per tour between adults and children.
                Specifically in a ratio of 4 adults and 4 children/teenagers.
              </li>
              <li>
                <strong
                  >Only one package purchase per friends or family is
                  allowed</strong
                >
                for this or any similar promotion. If friends and/or family
                staying at the same resort during the same or similar dates
                occupies two rooms, a higher rate must be paid for the 2nd room.
                <strong
                  >Omission of information and/or misleading of pertinent
                  information regarding group travel may be subject to your
                  reservation being cancelled without notice.</strong
                >
              </li>
            </ul>
          </div>
          <div class="pt-4 pb-5" id="main-10">
            <h2>Additional Terms</h2>
            <ul>
              <li>
                Relatives of timeshare industry employees and timeshare industry
                related individuals, Universal Vacation Club members, property
                owners in Los Cabos, Puerto Vallarta, Loreto or Cancun do not
                qualify for this promotion.
              </li>
              <li>
                Package Purchasers not meeting the above qualifications, not
                completing the 90 minute tour, or giving purposeful misleading
                information, and/or omitting relevant information regarding
                package qualifications, will negate this promotion and be
                subject to your reservation being cancelled without notice.
              </li>
              <li>
                To receive this significantly discounted package you are
                obligated to participate in the 90 minute informative tour and
                presentation at the resort sponsoring your vacation, on the
                morning after your arrival to Mexico. You are required to keep
                this day free of other obligations.
              </li>
              <li>
                <strong>
                  A major credit card must be presented at the tour check in to
                  qualify for this promotion and must be shown by the customer
                  who qualifies for this promotional package. (Please note that
                  company credit cards, debit/check cards do not
                  qualify.)</strong
                >
              </li>
              <li>
                Villa Group Resorts reserves the right to cancel and/or change
                your reservation location to an equal or better resort as
                circumstances so require it.
              </li>
              <li>
                VILLA GROUP RESORTS RESERVES THE RIGHT TO ALTER ITS TERMS AND
                CONDITIONS AND TERMS OF PROMOTIONS AS DEEMED NECESSARY BY
                OPERATIONAL PROCEDURES AND/OR AS OTHERWISE MANDATED BY STATE OR
                FEDERAL LAWS. THIS OFFER SUPERSEDES ALL OTHER REPRESENTATIONS
                MADE IN REGARD TO THIS OFFER, VERBAL OR WRITTEN AND VGR ASSUMES
                NO RESPONSIBILITY FOR ANY VERBAL OR WRITTEN REPRESENTATIONS MADE
                IN CONJUNCTION WITH THIS OFFER OTHER THAN THOSE SPECIFICALLY
                STATED HEREIN.
              </li>
            </ul>
          </div>
          <div class="alert alert-warning">
            <p>
              IMPORTANT: FAILURE TO MEET ALL TERMS AND CONDITIONS MAY RESULT IN
              YOUR RESERVATION BEING CANCELLED AT ANY TIME UP TO AND INCLUDING
              YOUR DATE OF ARRIVAL.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.oh-golds {
  font-family: myFirstFont;
color: white;
  font-size: 50px;
  font-weight: 100;
}
.cover {
  background-image: url('~@/assets/images/layout/cover-terms.jpg');
          height: 100h;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        margin-top: 0px;
}
a {
  text-decoration: none;
  color: black;
}
h2,
h4 {
  color: #006581;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
@media (max-width: 991px) {
  .cover {
  background-image: url('~@/assets/images/layout/cover-terms.jpg');
          height: 100h;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        margin-top: 60px;
}
}
.navbar-bg {
  background-color: #101014;
}

.nav-link {
  display: block;
  padding: 0.5rem 0.5rem;
  color: rgb(18, 18, 18);
  text-decoration: none;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s;
}

.nav-link-2 {
  display: block;
  padding: 0.75rem 0.5rem;
  margin: 0.25rem;
  color: rgb(18, 18, 18);
  text-decoration: none;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s;
  border: solid rgb(18, 18, 18) 1px;
}
hr {
  background-color: #9c7c46;
  color: #9c7c46;
}
</style>