<template>
    <MainBanner />
    <ReferralsForm />
    <Carousel />
    <ContentSection />
    <ContactForm />
</template>

<script>
import MainBanner from '@/components/layout/MainBanner.vue'
import ReferralsForm from '@/components/layout/ReferralsForm.vue'
import ContentSection from '@/components/layout/ContentSection.vue'
import ContactForm from '@/components/layout/ContactForm.vue'
import Carousel from '@/components/layout/Carousel.vue'


export default {
    name: 'Home',
    components: {
    MainBanner,
    ReferralsForm,
    ContentSection,
    ContactForm,
    Carousel,
  },
    methods: {
    pageNotFound() {
      return this.$swal.fire({
        customClass: {
          title: "swal2-title",
          text: "swal2-text",
        },
        title: "Oops! something went wrong.",
        text: "Please use the url provided on your email.",
        color: "#0C9491",
        backdrop: `rgba(0,101,129,0.7)`,
        icon: "warning",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
  },
  mounted() {
if (this.$route.query.ref == null) {
  // this.pageNotFound();
}
console.log(this.$route.query.ref)
  }
}
</script>