<template>
      <div class="mb-1 pe-3 ps-3 pb-5" id="privacy">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <section
      class=" text-center bg-image"

    >
                  <div class="row py-lg-5 cover text-center ">
        <div class="col-lg-8 col-md-8 mx-auto">
          <h1 class="pb-0 pt-5 oh-golds text-light fw-normal">Privacy Policy</h1>
          <p class="lead p-3">
    Privacy policy and management of personal data.
          </p>
        </div>
      </div>
            </section>
            <p class="pt-5">
              In accordance with the Federal Protection Law of Personal Data in
              Possession of Third parties, please be informed that concerning,
              The Villa Group NetCenter, with address on Boulevard Francisco
              Medina Ascencio KM 2.5 North Hotel Zone, Puerto Vallarta, Jalisco,
              and affiliate companies, uphold the following commitments:
            </p>
            <ul>
              <li>
                The information solicited during contact with the group, from
                the first telephonic conversation, invitation, internet
                reservations request, to the data disclosed by the client during
                the hotel stay, will be solely used to maintain the information
                up to date and to offer you a better service in accordance to
                your interests.
              </li>
              <li>
                The data acquired will not be released or commercialized; they
                will be kept in our installations with the proper security
                measures.
              </li>
              <li>
                If you wish to see your personal information with the purpose of
                updating, correcting, removing, or blocking it please notify us
                at eduardo@premiumvacationsonline.com, indicating your name,
                full address, purpose of the email. Be as detailed as possible
                so we can act in your behalf accordingly.
              </li>
              <li>
                Be aware that any change or modification that results from this
                request will be notified to you through either printed,
                electronic, digital, visual or any other technological means.
              </li>
            </ul>
          </div>
        </div>
      </div>
</template>

<style scoped>
h1 {
      font-family: myFirstFont;
}
.container {
  padding-top: 105px;
}
.cover {
  background-image: url('~@/assets/images/layout/cover-terms.jpg');
          height: 100h;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: white;
        margin-top: 0px;
}
@media (max-width: 991px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
  .cover {
    padding-top: 100pt;
  }
}
</style>