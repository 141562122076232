import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import App from './App.vue';
import Privacy from './components/Privacy.vue';
import Home from './components/Home.vue';
import Terms from './components/Terms.vue';
import NotFound from './components/NotFound.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        // { path: '/', redirect: 'home'},
        { 
            name: 'home',
            path: '/', 
            component: Home,
        },
        { name: 'privacy', path: '/privacy', component: Privacy },
        { name: 'terms&Conditions', path: '/terms', component: Terms },
        { path:'/:notFound(.*)', component: NotFound}
    ],

})

const app = createApp(App);
app.use(router);
app.mount('#app');
app.use(VueSweetalert2);