<template>
  <h1></h1>
</template>

<script>
export default {
  methods: {
    pageNotFound() {
      return this.$swal.fire({
        customClass: {
          title: "swal2-title",
          text: "swal2-text",
        },
        title: "Oops! something went wrong.",
        text: "Please use the url provided on your email.",
        color: "#0C9491",
        backdrop: `rgba(0,101,129,0.7)`,
        icon: "warning",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
  },
  mounted() {
    this.pageNotFound();
  },
};
</script>

<style>
.swal2-title {
  color: #0c9491 !important;
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
  font-size: 32px;
}
.swal2-text {
  font-size: 16px;
}
</style>