<template>
  <TheNavigation />
  <router-view></router-view>
  <TheFooter />
</template>

<script>
import TheNavigation from '@/components/layout/TheNavigation.vue'
import TheFooter from '@/components/layout/TheFooter.vue'

export default {
  name: 'App',
  components: {
    TheNavigation,
    TheFooter
  }
}
</script>

<style>
body {
  background-color: #e2efe8;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  font-family:'Montserrat', sans-serif;
   position: relative;
}
</style>

