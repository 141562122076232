<template>
  <!--Footer-->
  <div class="container-flex">
    <footer>
      <div class="row footer justify-content-center m-auto">
        <div class="col-lg-3 align-self-center" align="center">
          Follow us
          <a href="https://www.facebook.com/mexicoisamzing" class="ps-2">
            <img src="~@/assets/icons/fb-icon.svg" alt="" />
          </a>
          <a
            href="https://www.instagram.com/mexicois.amazing/?hl=en"
            class="ps-0"
          >
            <img src="~@/assets/icons/ig-icon.svg" alt="" />
          </a>
        </div>
        <div class="col-lg-3 align-self-center" align="center">
          <!-- <a style="margin-right: 20px;">Terms & conditions</a> -->
          <span @click="selectedOption('#terms')">
          <router-link :to="termsLink()"> Terms & conditions</router-link>
          </span>
          <span @click="selectedOption('#privacy')">
          <router-link :to="privacyLink()">Privacy Policy</router-link>
          </span>
          <!-- <a>Privacy Policy</a> -->
        </div>
      </div>

      <div class="row footBar justify-content-center p-3 m-auto">
        <div class="col-lg-2 align-self-center" align="center">
          ©2022 All Rights Reserved.
        </div>
        <div class="col-lg-10 align-self-center f-label" align="right">
          <a>Amazing Mexico</a>
        </div>
      </div>
    </footer>
  </div>
  <!--Ends Footer-->
</template>

<script>
export default {
  data() {
    return {
      referrer: '',
    };
  },
  methods: {
        selectedOption(option) {
      window.location.hash = option;
    },
    privacyLink() {
      this.referrer = this.$route.query.ref;
      return { name: "privacy", query: { ref: this.referrer } };
    },
        termsLink() {
      this.referrer = this.$route.query.ref;
      return { name: "terms&Conditions", query: { ref: this.referrer } };
    },
  },
};
</script>


<style scoped>
a {
  margin-right: 20px;
  text-decoration: none;
  color: black;
}

.footBar {
  background: linear-gradient(
    156deg,
    rgba(5, 116, 137, 1) 42%,
    rgba(177, 213, 62, 1) 100%
  );
  height: auto;
  color: white;
  font-size: 15px;
}

.footer {
  height: 100px;
  background-color: white;
}

@media (max-width: 991px) {
  .f-label {
    text-align: center;
  }
}


</style>