<template>
  <!--SecCarousel-->
  <div class="container mb-1 p-3" id="whn">
    <div class="row">
      <div class="col-sm-12 col-lg-8 referalsTxt">
        <h1 class="h1-mobile">What happens next?</h1>
        <br>
        <p>
          Your friend(s) will receive an exclusive invitation providing details about the special
          package setup for them. Please make sure that either you submit their contact
          information for our Referral Concierge Team to get in touch with them. </p>
        <p>After a <span class="am-txt"> Amazing Mexico </span> representative contacts them and verifies their
          eligibility to receive our special discount package and they accept our invitation offer
          to visit, they will need to attend the entire sales presentation at one of our Resorts in
          order for you to qualify for bonus rewards.</p>
        <p>You will then be given earned bonus within 4 weeks after your friend(s) attend the
          sales presentation, as long as your membership is not closed, canceled or past due.</p>
      </div>
      <div class="col-sm-12 col-lg-4 ">
        <img src="~@/assets/images/layout/family.png" alt="" class="img-fluid">
      </div>
    </div>
  </div>
  <!--Ends SecCarousel-->


  <!--FAQs-->
  <div class="container p-3" id="faqs">
    <div class="row">
      <h1 class="h1-mobile mb-3">FAQs</h1>
    </div>
    <div class="row">
      <div class="col-sm">
        <div class="row top-buffer mb-3">
          <div class="d-grid gap-2">
            <a class="btn  btn-collapse" data-bs-toggle="collapse" href="#collapseInfo1" role="button"
              aria-expanded="false" aria-controls="collapseInfo1">
              How it works?
              <i class="material-icons-outlined faq-icon">
                expand_circle_down
              </i>
              <div class="collapse" id="collapseInfo1">
                <!-- <div class="card card-body"> -->
                <br>Provide the name(s) of anyone you think would enjoy such as family members, friends, or anyone you
                may
                know will enjoy a vacation at any of our partner Beach Resorts and Spas. We will offer them a 5 Days & 4
                nights vacation so they can experience our beautiful properties at an incredible discount only available
                through this referral offer. <b>Our Referral Sales Team will assist them with booking their stay and
                  customizing it to suit their vacation preferences.</b>
                <!-- </div> -->
              </div>
            </a>
          </div>
        </div>
        <div class="row top-buffer mb-3">
          <div class="d-grid gap-2">
            <a class="btn btn-collapse" data-bs-toggle="collapse" href="#collapseInfo2" role="button"
              aria-expanded="false" aria-controls="collapseInfo1">
              How do I know my friend is eligible to participate as my referrals?
              <i class="material-icons-outlined faq-icon">
                expand_circle_down
              </i>
              <div class="collapse" id="collapseInfo2">
                <br>Although anyone can visit our properties or purchase a membership, our special referral discount
                preview
                packages are only available to clients who meet certain criteria, such as a major credit card, an annual
                income of + 50k and be at least 30 years old at the time of traveling. Married couples or single people
                are also welcomed to enjoy this amazing offer.
              </div>
            </a>
          </div>

        </div>
        <div class="row top-buffer mb-3">
          <div class="d-grid gap-2">
            <a class="btn btn-collapse" data-bs-toggle="collapse" href="#collapseInfo3" role="button"
              aria-expanded="false" aria-controls="collapseInfo2">
              What are the terms and conditions my referrals need to know?
              <i class="material-icons-outlined faq-icon">
                expand_circle_down
              </i>
              <div class="collapse" id="collapseInfo3">
                <br>They need to agree to attend a 90–120-minute Vacation Ownership presentation with Garza Blanca or
                Villagroup, in the destination they choose among our participating resorts. Whether married or
                cohabitating, guests must travel and attend the presentation together, be currently employed with a
                household combined annual income of $50k USD / $75k CAD or greater, be between the ages of 30 – 70, have
                and present a major credit card as secondary ID.
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="row top-buffer mb-3">
          <div class="d-grid gap-2">
            <a class="btn btn-collapse" data-bs-toggle="collapse" href="#collapseInfo4" role="button"
              aria-expanded="false" aria-controls="collapseInfo1">
              What are my rewards for the Referrals?
              <i class="material-icons-outlined faq-icon">
                expand_circle_down
              </i>
              <div class="collapse" id="collapseInfo4">
                <p><br>There are two options: You can get up to 50 USD credit for Amazon or up to a 100% refund of your
                  package. This bonus will be granted based on your referral’s conversion to sales.</p>
                <h6>Table of prizes</h6>
                <table class="table ">
                  <thead>
                    <tr>
                      <th scope="col">Referred Couples</th>
                      <th scope="col">prize</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">2</td>
                      <td class="text-start">USD 100.00 cashback.</td>
                    </tr>
                    <tr>
                      <td scope="row">3</td>
                      <td  class="text-start">1 extra night in your vacation package (based on availability and hotel authorization).</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td scope="row">7</td>
                      <td  class="text-start">A 4-day, 3-night package usable at any of our all-inclusive destinations for 2 adults and 2
                        children under 12 years old. (The customer is responsible for paying the resort fees associated
                        with the package and cannot use it for the same destination.)</td>
                    </tr>
                  </tbody>
                </table>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">REFERRALS PURCHASE</th>
                      <th scope="col">PACKAGE CASH BACK</th>
                      <th scope="col">LIMIT OF TIME</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">1</td>
                      <td>-</td>
                      <td rowspan="10">90 DAYS</td>
                    </tr>
                    <tr>
                      <td scope="row">2</td>
                      <td>-</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td scope="row">3</td>
                      <td>-</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td scope="row">4</td>
                      <td>40%</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td scope="row">5</td>
                      <td>-</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td scope="row">6</td>
                      <td>-</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td scope="row">7</td>
                      <td>-</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td scope="row">8</td>
                      <td>80%</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td scope="row">9</td>
                      <td>-</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td scope="row">10</td>
                      <td>100%</td>
                      <td></td>
                    </tr>

                  </tbody>
                </table>
                <p><b>Terms & Conditions:</b></p>
                <ul>
                  <li>Referrals are not cumulative: If the client chooses the first tier (USD 100.00), they cannot
                    receive the same incentive twice; they would need to qualify for the second tier. Additionally,
                    previous referrals do not count toward reaching the second tier.</li>
                  <li>Extra nights are based on availability and hotel authorization.</li>
                  <li>The client must pay the resort fees associated with the package and cannot use it for the same
                    destination as the free package.</li>
                  <li>The package is transferable as long as the recipient meets the qualification criteria.</li>
                  <li>To request any of the aforementioned benefits, it will be necessary to send an email with the
                    referrer's and the referred individual's information.</li>
                  <li>To validate the incentives, the certificates must undergo a pre-verification process.</li>
                  <li>The refund for the first tier will be credited directly to the same account used for the
                    certificate payment.</li>
                </ul>
              </div>
            </a>
          </div>
        </div>
        <div class="row top-buffer mb-3">
          <div class="d-grid gap-2">
            <a class="btn btn-collapse" data-bs-toggle="collapse" href="#collapseInfo5" role="button"
              aria-expanded="false" aria-controls="collapseInfo1">
              Is there a deadline to register my referrals and earn rewards?
              <i class="material-icons-outlined faq-icon">
                expand_circle_down
              </i>
              <div class="collapse" id="collapseInfo5">
                <br>If you want to participate in our program and get rewards, you must submit your referrals within the
                next
                30 days from your purchase. If you do not qualify for the promotion or don’t have a package, you can
                still
                participate. The purchases of the packages can occur in the range of 6 months to participate in the
                rewards.
              </div>
            </a>
          </div>
        </div>
        <div class="row top-buffer mb-3">
          <div class="d-grid gap-2">
            <a class="btn btn-collapse" data-bs-toggle="collapse" href="#collapseInfo6" role="button"
              aria-expanded="false" aria-controls="collapseInfo1">
              Where do referrals stay?
              <i class="material-icons-outlined faq-icon">
                expand_circle_down
              </i>
              <div class="collapse" id="collapseInfo6">
                <br>Referrals will choose one of our ocean fronts resorts either in Cabo, Puerto Vallarta and Cancun /
                Riviera
                Maya, Mexico.
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Ends FAQs-->

</template>

<script>

</script>

<style>
.faq-icon {
  color: #00000029;
  float: right;

}

h1 {
  color: #006581;
  font-weight: bold;
}

.btn-collapse:hover,
/* .btn-collapse:active, */
.btn-collapse:focus,
.btn-collapse[aria-expanded="true"] {
  border-color: #006581;
  /* border-right-color: #006581;
  border-left-color: #006581; */
  margin-bottom: 0px;
  border-radius: 0px;
}

.am-txt {
  color: #AED138;
  font-weight: bold;
}

.btn-collapse {
  border-color: #02C0D1;
  border-width: 3px;
  margin-bottom: 0px;
  border-radius: 0px;
  text-align: left;
}

.referalsTxt {
  padding-right: 180px;
}

@media (max-width: 991px) {
  .h1-mobile {
    font-size: 40pt;
  }

  .referalsTxt {
    padding-right: 50px;
  }
}

tr {
  text-align: center;

}

td {
  vertical-align: middle;
}
</style>